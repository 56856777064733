<template>
  <div class="popver">
    <div class="popver-l" v-if="row.auditStatus == 3">
      <el-popover
        placement="top-start"
        :title="returnTitle(row)"
        width="200"
        trigger="hover"
        :content="row.auditRemarks"
      >
        <div class="popverStatus" slot="reference">
          <div :class="['popverStatus-l', 'status' + row.auditStatus]">
            {{ shelfStsMap[row.auditStatus] }}
          </div>
          <div class="popverStatus-r">
            <img :src="popverStatus[row.auditStatus]" alt="" />
          </div>
        </div>
      </el-popover>
    </div>
    <div class="popver-r" v-else>
      <div class="popverStatus">
        <div :class="['popverStatus-l', 'status' + row.auditStatus]">
          {{ shelfStsMap[row.auditStatus] }}
        </div>
        <div class="popverStatus-r">
          <img :src="popverStatus[row.auditStatus]" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appType, appTypeMap, shelfStsMap, shelfSts } from "./map";
export default {
  name: "popver",
  data() {
    return {
      shelfStsMap,
      visible: false,
      popverStatus: {
        0: require("./img/waitOn.png"),
        1: require("./img/waiting.png"),
        2: require("./img/success.png"),
        3: require("./img/reject.png"),
      },
    };
  },
  props: {
    row: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    returnTitle(row) {
      if (row.auditStatus == 0) {
        return "未授权";
      }
      if (row.auditStatus == 1) {
        return "待审核";
      }
      if (row.auditStatus == 2) {
        return "备注";
      }
      if (row.auditStatus == 3) {
        return "拒绝原因";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.popver {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  .popverStatus {
    display: flex;
    align-items: center;
    .popverStatus-l {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 18px;
    }
    .popverStatus-r {
      width: 14px;
      height: 14px;
      margin-left: 5px;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .status0 {
      color: rgba(0, 0, 0, 0.5);
    }
    .status1 {
      color: #ffb800;
    }
    .status2 {
      color: #79c296;
    }
    .status3 {
      color: #e02020;
    }
  }
}
</style>
<style lang="less">
.popverRemark {
  padding: 13px 15px 8px;
  .title {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    margin-bottom: 7px;
  }
  .content {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 17px;
  }
}
</style>