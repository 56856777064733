<template>
  <div class="myApplication">
    <v-list
      ref="list"
      :isAdd="true"
      :border="false"
      :headers="clientType == 0 ? headers : headersClient"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :operateColumnWidth="380"
      operateColumnAlign="center"
      :afterReturnList="afterList"
    >
      <template #headBtnSlot>
        <v-button
          text="新增应用"
          :width="112"
          v-if="clientType == 0"
          @click="addApply"
        ></v-button
      ></template>
      <template #searchSlot>
        <v-select
          clearable
          :options="appType"
          v-model="searchParam.appType"
          v-if="clientType != 0"
          @change="$refs.list.getList()"
          placeholder="全部应用"
        />
        <v-input
          placeholder="应用名称"
          v-model="searchParam.modelTitle"
          @keyup.enter.native="$refs.list.search()"
        />
        <v-select
          clearable
          :options="shelfStatusOps"
          v-model="searchParam.shelfStatus"
          v-if="clientType == 0"
          placeholder="应用状态"
        />
      </template>
      <template #operateSlot="scope">
        <template v-if="clientType == 0">
          <template
            v-if="scope.row.auditStatus == 0 || scope.row.auditStatus == 3"
            ><v-button
              :width="78"
              class="btn"
              text="修改"
              @click="toClick({ key: 1 }, scope.row)" />
            <v-button
              :width="78"
              class="btn"
              text="删除"
              @click="toClick({ key: 2 }, scope.row)"
          /></template>
          <template v-else>
            <v-button
              :width="78"
              class="btn grayBtn"
              text="修改"
              @click="tograyBtnClick({ key: 1 }, scope.row)" />
            <v-button
              :width="78"
              class="btn grayBtn"
              text="删除"
              @click="tograyBtnClick({ key: 2 }, scope.row)"
          /></template>
          <v-button
            :width="78"
            class="btn"
            text="上架"
            v-if="scope.row.auditStatus == 0 || scope.row.auditStatus == 3"
            @click="toClick({ key: 3 }, scope.row)"
          />
          <v-button
            :width="78"
            class="btn"
            text="撤回"
            v-if="scope.row.auditStatus == 1"
            @click="toClick({ key: 4 }, scope.row)"
          />
          <v-button
            :width="78"
            class="btn"
            text="下架"
            v-if="scope.row.auditStatus == 2"
            @click="toClick({ key: 4 }, scope.row)"
          />
          <v-button
            :width="78"
            class="btn"
            text="调试"
            @click="toClick({ key: 5 }, scope.row)"
          />
        </template>
        <template v-else
          ><v-button
            :width="78"
            class="btn"
            text="修改"
            @click="toClick({ key: 6 }, scope.row)"
          />
          <v-button
            :width="78"
            class="btn"
            text="删除"
            @click="toClick({ key: 2 }, scope.row)"
          />
        </template>
      </template>
    </v-list>
    <v-dialog
      title="提示"
      v-model="isdialog"
      @cancel="cancel"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="确定"
    >
      <span>是否删除?</span>
    </v-dialog>
    <v-dialog
      title="调试二维码"
      v-model="isTestdialog"
      :isDialogFooter="false"
      :center="true"
    >
      <div class="myApplication-code">
        <div class="item">
          <div class="item-t">
            <vue-qr :text="codeData.targetUrl" :size="size"></vue-qr>
          </div>
          <div class="item-b">h5页面</div>
        </div>
        <div class="item">
          <div class="item-t">
            <vue-qr :text="codeData.managerUrl" :size="size"></vue-qr>
          </div>
          <div class="item-b">管理后台</div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  deveAddApplyUrl,
  deveApplyUrl,
  devePubApplyUrl,
  deveDeleApplyUrl,
  comApplyUrl,
  comDeleApplyUrl,
  testQrCodeUrl,
} from "./api.js";
import popver from "./popver";
import vueQr from "vue-qr";
import {
  appType,
  appTypeMap,
  appStatusTypeMap,
  shelfSts,
  shelfStatusOps,
  shelfStatusMap,
} from "./map";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "myApplication",
  data() {
    return {
      isTestdialog: false,
      isdialog: false,
      selectId: "",
      appType,
      size: 200,
      postData: {},
      codeData: {},
      searchParam: {
        appType: undefined,
        modelTitle: "",
      },
      extraParams: {},
      tableUrl: "",
      headers: [
        {
          prop: "modelIconPic",
          label: "应用图标",
          align: "center",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "modelTitle",
          label: "应用名称",
          align: "center",
        },
        {
          prop: "modelDesc",
          label: "应用介绍",
          align: "center",
          minWidth: "182",
          showOverflowTooltip: true,
        },
        {
          prop: "shelfPopver",
          label: "状态",
          align: "center",
        },
        {
          prop: "shelfStatus",
          label: "上架状态",
          align: "center",
          formatter: (row, prop) => {
            if (row.auditStatus == 1) {
              return "";
            } else {
              return shelfStatusMap[row.shelfStatus];
            }
          },
        },
      ],
      headersClient: [
        {
          prop: "modelIconPic",
          label: "应用图标",
          align: "center",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "modelTitle",
          label: "应用名称",
          align: "center",
        },
        // {
        //   prop: "modelTitle",
        //   label: "应用状态",
        //   align: "center",
        // },
        {
          prop: "modelDesc",
          label: "应用介绍",
          minWidth: "182",
          align: "center",
          showOverflowTooltip: true,
        },
        {
          prop: "appStatus",
          label: "应用状态",
          align: "center",
          formatter: (row, prop) => appStatusTypeMap[row.appStatus],
        },
        {
          prop: "appType",
          label: "应用来源",
          align: "center",
          formatter: (row, prop) => appTypeMap[row.appType],
        },
      ],
      shelfStatusOps: shelfStatusOps(), //上架状态
    };
  },
  components: { vueQr },
  computed: {
    clientType() {
      return this.$store.state.app.userInfo.clientType;
    },
    operaTxt() {
      if (this.$store.state.app.userInfo.clientType == 0) {
        //开发者
        return [
          {
            key: "1",
            value: "修改",
          },
          {
            key: "2",
            value: "删除",
          },
          {
            key: "3",
            value: "上架",
          },
          {
            key: "4",
            value: "下架",
          },
        ];
      } else if (this.$store.state.app.userInfo.clientType == 1) {
        //园区
        return [
          {
            key: "2",
            value: "删除",
          },
        ];
      } else {
        return [
          {
            key: "1",
            value: "修改",
          },
          {
            key: "2",
            value: "删除",
          },
          {
            key: "3",
            value: "上架",
          },
          {
            key: "4",
            value: "下架",
          },
        ];
      }
    },
  },
  created() {
    if (this.$store.state.app.userInfo.clientType == 0) {
      this.tableUrl = deveApplyUrl;
    } else if (this.$store.state.app.userInfo.clientType == 1) {
      this.tableUrl = comApplyUrl;
    } else {
      this.tableUrl = comApplyUrl;
    }
  },
  mounted() {},
  methods: {
    async postQrCode() {
      let params = {
        id: this.postData.id,
      };
      let res = await this.$axios.post(
        `${testQrCodeUrl}`,
        this.$qs.stringify(params)
      );
      this.isTestdialog = true;
      if (res.code === 200) {
        this.codeData = res.data;
      } else {
      }
    },
    cancelTest() {},
    confirmTest() {
      this.isTestdialog = false;
    },
    afterList(data) {
      data.forEach((ele) => {
        ele.shelfPopver = {
          component: popver,
        };
      });
      return data;
    },
    search() {},
    init() {
      this.pages = {
        total: 0,
        curPage: 1,
        pageSize: 10,
      };
    },
    cancel() {
      this.isdialog = false;
    },
    confirm() {
      this.isdialog = false;
      this.deleteAppy(this.selectId, 1);
    },
    deleteAppy(ids, auditStatus) {
      let deleteUrl = "";
      if (this.clientType == 0) {
        deleteUrl = deveDeleApplyUrl;
      } else {
        deleteUrl = comDeleApplyUrl;
      }
      let params = {
        ids: ids,
      };
      this.$axios
        .post(`${deleteUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 500,
            });
            this.$refs.list.search();
          } else {
          }
        });
    },

    addApply() {
      this.$router.push({
        name: "addNumeralCommunyApplication",
      });
    },
    tograyBtnClick(items, item) {
      if (item.auditStatus == 1) {
        //审核中
        this.$message({
          message: "请先撤回后，再操作",
          type: "info",
          duration: 500,
        });
      }
      if (item.auditStatus == 2) {
        this.$message({
          message: "请先下架后，再操作",
          type: "info",
          duration: 500,
        });
        //审核通过
      }
    },
    toClick(items, item) {
      if (items.key == 1) {
        this.$router.push({
          name: "addNumeralCommunyApplication",
          query: {
            id: item.id,
          },
        });
      }
      if (items.key == 6) {
        this.$router.push({
          name: "editNumeralCommunyApplication",
          query: {
            id: item.id,
          },
        });
      }
      if (items.key == 2) {
        this.selectId = item.id;
        this.isdialog = true;
      }
      if (items.key == 3) {
        this.onShelf(item.id, 1);
      }
      if (items.key == 4) {
        this.onShelf(item.id, 2);
      }
      if (items.key == 5) {
        this.postData = item;
        this.postQrCode();
      }
    },
    onShelf(id, shelfStatus) {
      this.$confirm(
        shelfStatus == 1 ? "是否确定上架?" : "是否确定下架?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let params = {
          ids: id,
          shelfStatus: shelfStatus,
        };
        this.$axios
          .post(`${devePubApplyUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
              });
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    mouseLeave() {
      this.isIndex = "";
    },
    mouseEnter(index) {
      this.isIndex = index;
    },
    async toLogin() {},
    async getMenu() {},
  },
};
</script>

<style lang="less" scoped>
.myApplication {
  height: 100%;
  box-sizing: border-box;
  /deep/ .v-table .opera-btn {
    width: 100% !important;
    display: flex;
    justify-content: center;
    .v-button {
      margin: 0 4px;
    }
    .el-button--primary {
      width: 78px;
      height: 24px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #2f7cff;
      font-size: 12px;
      font-weight: 400;
      color: #2f7cff;
    }
    .grayBtn .el-button--primary {
      border: 1px solid #e3e3e3;
      color: #e3e3e3;
      cursor: auto;
    }
    .v-control {
    }
  }
}
</style>

<style lang="less">
.myApplication-code {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  .item {
    .item-t {
      width: 200px;
      height: 200px;
    }
    .item-b {
      text-align: center;
    }
  }
}
</style>
